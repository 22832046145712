export enum ROLE {
    NONE = 'NONE',
    ADMIN = 'ADMIN',
    USER = 'USER',
}

export enum ROLE_INT {
    NONE = 0,
    ADMIN = 1,
    USER = 2,
}

export const ROLES = [ROLE.NONE, ROLE.ADMIN, ROLE.USER];
